import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import imglogo from '../data/1.png';
import CloseIcon from '@mui/icons-material/Close';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import PhoneIcon from '@mui/icons-material/Phone';


function ContactUs() {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle function to open/close the sidebar
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="home-page">
      {/* Hamburger Menu */}
      <div className="hamburger-menu">
        <div className="menu-icon" onClick={toggleSidebar}>
          {isOpen ? <CloseIcon sx={{ fontSize: 40 }} /> : <MenuIcon sx={{ fontSize: 40 }} />}
        </div>
      </div>

      {/* Sidebar */}
      <div className={`sidebar1 ${isOpen ? 'open' : ''}`}>
        <div className="sidebar-close" onClick={toggleSidebar}>
          <CloseIcon sx={{ fontSize: 40 }} />
        </div>
        <ul className="sidebar-menu">
          <li><Link to="gettoknowus" smooth={true} duration={500}>About Us</Link></li>
          <li><Link to="card_container" smooth={true} duration={500}>Our Products</Link></li>
          <li><a href="#">Our Technologies</a></li>
          <li><a href="ContactUs">Contact Us</a></li>
          <li><a href="Blog">Blogs</a></li>
        </ul>
      </div>

     {/* Navigation Bar Divided into Left and Right */}
     <nav className="enc_prod_nav">
  <div className="nav-container">
    <ul className="nav-links">
    <li><a href="Contact_us">About Us</a></li>
    <li><a href="Our_Products">Our Products</a></li>
      <li><a href="Blog">Blogs</a></li>
      <li>
        <a href="/">
          <img src={imglogo} alt="Ingredis Logo" className="alt_logo" />
        </a>
      </li>
      <li><a href="Our_tech">Our Technologies</a></li>
      <li><a href="ContactUs">Contact Us</a></li>
      <li><a href="tel:+971585926115"><PhoneIcon/></a></li>

    </ul>
  </div>
</nav>
      <div className="cf_head">
        <h1>GET IN TOUCH</h1>
      </div>

      {/* Contact Us Page */}
      <div className="contact-us-page">
        {/* Left Side: Google Map */}
        <div className="contact-us-map">
          <a href="https://goo.gl/maps/xyz" target="_blank" rel="noopener noreferrer">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.663583611847!2d72.574383!3d23.019525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84ee8fffd999%3A0x98ae99eb021542bc!2sArtcatera%20Design%20Studio!5e0!3m2!1sen!2sin!4v1640940399585!5m2!1sen!2sin"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Company Location"
            ></iframe>
          </a>
        </div>

        {/* Right Side: Contact Form */}
        <div className="contact-us-form">
          <h2>Write To Us</h2>
          <form>
            <label htmlFor="name">Your Name</label>
            <input type="text" id="name" name="name" required placeholder="Enter your name" />

            <label htmlFor="email">Your Email</label>
            <input type="email" id="email" name="email" required placeholder="Enter your email" />

            <label htmlFor="subject">Subject</label>
            <input type="text" id="subject" name="subject" required placeholder="Enter subject" />

            <label htmlFor="message">Your Message (optional)</label>
            <textarea id="message" name="message" placeholder="Enter your message"></textarea>

            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
