import React, { useState } from 'react';
import bgvid from '../data/Ingredis without text.mp4';
import imglogo from '../data/Ingredis white.png';
import footlogo from '../data/1.png'
import fooding from '../data/Gluten_pure.jpg';
import feeding from '../data/Feed.jpg';
import gum_acacia from '../data/Gum_acacia.jpg'
import enc_prod from '../data/Enc-prod.jpg';
import gtku from '../data/GTKU.jpg';
import logopng from '../data/2.png';
import ContactUs from './about_us';
import Sidebar from './sidebar';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link, Element, animateScroll as scroll } from 'react-scroll';

function Home() {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle function to open/close the sidebar
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="home-page">
  {/* Hamburger Menu */}
  <div className="hamburger-menu">
    <div className="menu-icon" onClick={toggleSidebar}>
      {isOpen ? <CloseIcon sx={{ fontSize: 40 }}/> : <MenuIcon sx={{ fontSize: 40 }}/> }
    </div>
  </div>

  {/* Sidebar */}
  <div className={`sidebar1 ${isOpen ? 'open' : ''}`}>
    <div className="sidebar-close" onClick={toggleSidebar}>
      <CloseIcon sx={{ fontSize: 40 }} />
    </div>
    <ul className="sidebar-menu">
      <li>
        <Link to="gettoknowus" smooth={true} duration={500}>
          About Us
        </Link>
      </li>
      <li><a href="Our_products"></a>Our Products</li>
      <li><a href="Blog">Blogs</a></li>
      <li><a href="Our_tech">Our Technologies</a></li>
      <li><a href="ContactUs">Contact Us</a></li>
    </ul>
  </div>

 {/* Navigation Bar Divided into Left and Right */}
 <nav className="home-page__nav">
  <div className="nav-container">
    <ul className="nav-links">
      <li><a href="Contact_us">About Us</a></li>
      <li><a href="Our_Products">Our Products</a></li>
      <li><a href="Blog">Blogs</a></li>
      <li>
        <a href="/">
          <img src={imglogo} alt="Ingredis Logo" className="home-page__logo" />
        </a>
      </li>
      <li><a href="Our_tech">Our Technologies</a></li>
      <li><a href="ContactUs">Contact Us</a></li>
      <li><a href="tel:+971585926115"><PhoneIcon/></a></li>

    </ul>
  </div>
</nav>


      {/* Video Background */}
      <video src={bgvid} autoPlay loop muted className="home-page_bgvid" />
      <div className="gtku">
      <img src={gtku} alt="foing-card" />

      </div>

      <section className='card_container'>
        <h1>
          Our Product Ranges
        </h1>
        <p>
          Our brand specializes in delivering top quality encapsulated products, food ingredients, feed
          ingredients and Gum Acacia.
        </p>
        <div className="card_holder">
        <a href="Food_ing">
          <div class="card">
            <img src={fooding} alt="foing-card" />
            <div class="card-content">
              <h2 class="card-title">Food Ingredients</h2>
              {/* <p class="card-text">This is a brief description of the card content. It provides additional details about the item or topic represented by the card.</p>
              <a href="Food_ing" class="card-link">Read More</a> */}
            </div>
          </div>
          </a>
          <a href="Feed_ing">
            <div class="card">
              <img src={feeding} alt="foing-card2" />
              <div class="card-content">
                <h3 class="card-title">Feed Ingredients</h3>
              {/* <p class="card-text">This is a brief description of the card content. It provides additional details about the item or topic represented by the card.</p>
              <a href="Feed_ing" class="card-link">Read More</a> */}
              </div>
            </div>
            </a>
          <a href="">
          <div class="card">
            <img src={gum_acacia} alt="foing-card3" />
            <div class="card-content">
              <h4 class="card-title">Gum Acacia</h4>
              {/* <p class="card-text">This is a brief description of the card content. It provides additional details about the item or topic represented by the card.</p>
              <a href="#" class="card-link">Read More</a> */}
            </div>
          </div>
          </a>
          <a href="Enc_prod">
          <div class="card">
            <img src={enc_prod} alt="foing-card" />
            <div class="card-content">
              <h2 class="card-title">Encapsulated Products</h2>
              {/* <p class="card-text">This is a brief description of the card content. It provides additional details about the item or topic represented by the card.</p>
              <a href="Enc_prod" class="card-link">Read More</a> */}
            </div>
          </div>
          </a>
        </div>

      </section>
      <section className="container">
  <div className="left-side">
    <h1>INGREDIS GENERAL TRADING COMPANY</h1>
    <img src={footlogo} alt="Ingredis Logo" className="logo-end" />
    {/* <p>Address:</p>
    <p2>1414, Sabha Building, Jabal Ali Industrial First, Dubai, UAE</p2>
    <p3>Email:</p3>
    <p4><a href="mailto:info@ingredis.ae">info@ingredis.ae</a></p4>
    <p5>Contact No:</p5>
    <p6><a href="tel:+971585926115">+971585926115</a>  , 
    <a href="tel:+971509208161">+971509208161</a></p6> */}
  <div className="icons">
      {/* Email Icon wrapped with mailto link */}
      <a href="mailto:info@ingredis.ae" title="Send us an email">
        <EmailOutlinedIcon/>
      </a>

      {/* Location Icon wrapped with Google Maps link */}
      <a
        href="https://www.google.com/maps?q=1414,+Sabha+Building,+Jabal+Ali+Industrial+First,+Dubai,+UAE"
        target="_blank"
        rel="noopener noreferrer"
        title="View our location"
      >
        <LocationOnIcon/>
      </a>

      {/* Phone Icon wrapped with tel link */}
      <a href="tel:+971585926115" title="Call us">
        <PhoneIcon/>
      </a>
    </div>
  </div>
  <div className="right-side">
        <a href="Contact_us">
        <p1>
          About us</p1>
        </a>
      <a href="Our_Products">
    <p6>
          Our Products
        </p6>
        </a>
    <a href="Our_tech"><p7>Our Technologies</p7></a>
    <a href="ContactUs"><p8>Contact Us</p8></a>
    <a href="Blogs"><p9>Blogs</p9></a>
  </div>
</section>
<div className="footer">
    <p7>©</p7>
    <p8>INGREDIS GENERAL TRADING COMPANY</p8>
  </div>

    </div>
  );
}

export default Home;