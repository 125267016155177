import React from 'react';
import imglogo from '../data/1.png'; // Import for the logo image
import feeding from '../data/Feed.jpg';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';


function Blog() {
    return (
        <div className="home-page">
      
       {/* Navigation Bar Divided into Left and Right */}
       <nav className="enc_prod_nav">
          <div className="enc_prod_nav-left">
            <ul>
              <li>
                <Link to="gettoknowus" smooth={true} duration={500}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="card_container" smooth={true} duration={500}>
                  Our Products
                </Link></li>
            </ul>
          </div>
      
          {/* Centered Logo */}
          <div className="enc_prod_nav-center">
            <a href="/">
              <img src={imglogo} alt="Ingredis Logo" className="home-page__logo" />
            </a>
          </div>
      
          <div className="enc_prod_nav-right">
            <ul>
              <li><a href="#">Our Technologies</a></li>
              <li><a href="ContactUs">Contact Us</a></li>
              <li><a href="Blog">Blogs</a></li>
            </ul>
          </div>
        </nav>
            <div className="title">
                <h1>BLOG</h1>
            </div>
            <section className='bpg_content'>
                <div className="card_holder">
                    <div className="card_bpg">
                        <img src={feeding} alt="Food Ingredients" />
                        <div className="card-content">
                            <h2 className="card-title">The Role of Yeasts <br /> in Fermentation</h2>
                            <a href="#" className="card-link">Read More</a>
                        </div>
                    </div>
                    <div className="card_bpg">
                        <img src={feeding} alt="Food Ingredients" />
                        <div className="card-content">
                            <h2 className="card-title">The Role of Food Preservation <br /> in Reducing Waste</h2>
                            <a href="#" className="card-link">Read More</a>
                        </div>
                    </div>
                    <div className="card_bpg">
                        <img src={feeding} alt="Food Ingredients" />
                        <div className="card-content">
                            <h2 className="card-title">Food Ingredients</h2>
                            <a href="#" className="card-link">Read More</a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Blog;
