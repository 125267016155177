import React, { useState } from 'react';
import imglogo from '../data/1.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import footlogo from '../data/1.png'
import about_us from '../data/about_us.jpg'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import techcontent from '../data/Our Technologies Page.png';
import { Link, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';



function Contact_us() {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle function to open/close the sidebar
    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };
      return (
        <div className="home-page">
        {/* Hamburger Menu */}
        <div className="hamburger-menu">
          <div className="menu-icon" onClick={toggleSidebar}>
            {isOpen ? <CloseIcon sx={{ fontSize: 40 }}/> : <MenuIcon sx={{ fontSize: 40 }}/> }
          </div>
        </div>
      
        {/* Sidebar */}
        <div className={`sidebar1 ${isOpen ? 'open' : ''}`}>
          <div className="sidebar-close" onClick={toggleSidebar}>
            <CloseIcon sx={{ fontSize: 40 }} />
          </div>
          <ul className="sidebar-menu">
            <li>
              <Link to="gettoknowus" smooth={true} duration={500}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="card_container" smooth={true} duration={500}>
                Our Products
              </Link>
            </li>
            <li><a href="#">Our Technologies</a></li>
            <li><a href="ContactUs">Contact Us</a></li>
            <li><a href="Blog">Blogs</a></li>
          </ul>
        </div>
      
       {/* Navigation Bar Divided into Left and Right */}
       <nav className="enc_prod_nav">
  <div className="nav-container">
    <ul className="nav-links">
    <li><a href="Contact_us">About Us</a></li>
    <li><a href="Our_Products">Our Products</a></li>
      <li><a href="Blog">Blogs</a></li>
      <li>
        <a href="/">
          <img src={imglogo} alt="Ingredis Logo" className="alt_logo" />
        </a>
      </li>
      <li><a href="Our_tech">Our Technologies</a></li>
      <li><a href="ContactUs">Contact Us</a></li>
      <li><a href="tel:+971585926115"><PhoneIcon/></a></li>

    </ul>
  </div>
</nav>
<div className="cu-heading"><h1>
          About Us
        </h1>
        <p>
        At Ingredis, we are committed to delivering excellence in the High Quality products and chemicals.. With our headquarters based in India and a robust presence in Dubai and Gulf, we bring together innovation, quality, and reliability to cater to the diverse needs of the food industry.<br /><br />

Driven by a passion for excellence, Ingredis is dedicated to upholding the highest standards of safety and quality in every aspect of our operations. Our state-of-the-art manufacturing facilities ensure that our products meet stringent regulatory requirements, providing our customers with peace of mind and confidence in the safety of their food products.
        </p></div>
        <div className="cu-banner">
        <img src={about_us} alt="content" />
        </div>
    <div className="cu-para">
      <p>
      “At Ingredis, we believe that exceptional outcomes start with 
exceptional ingredients. Our dedication to quality and innovation 
ensures that every product we offer enhances your culinary 
creations and drives success in every kitchen.”

        </p></div>    

    <section className="container_foo">
  <div className="left-side_foo">
    <h1>INGREDIS GENERAL TRADING COMPANY</h1>
    <img src={footlogo} alt="Ingredis Logo" className="logo-end_foo" />
    <div className="icons">
      {/* Email Icon wrapped with mailto link */}
      <a href="mailto:info@ingredis.ae" title="Send us an email">
        <EmailOutlinedIcon/>
      </a>

      {/* Location Icon wrapped with Google Maps link */}
      <a
        href="https://www.google.com/maps?q=1414,+Sabha+Building,+Jabal+Ali+Industrial+First,+Dubai,+UAE"
        target="_blank"
        rel="noopener noreferrer"
        title="View our location"
      >
        <LocationOnIcon/>
      </a>

      {/* Phone Icon wrapped with tel link */}
      <a href="tel:+971585926115" title="Call us">
        <PhoneIcon/>
      </a>
    </div>
  </div>
  
  <div className="right-side_foo">
  <a href="Contact_us">
        <p2>
          About us</p2>
        </a>
      <a href="Our_Products">
    <p6>
          Our Products
        </p6>
        </a>
        <a href="Our_tech"><p7>Our Technologies</p7></a>
    <a href="ContactUs"><p8>Contact Us</p8></a>
    <a href="Blogs"><p9>Blogs</p9></a>
  </div>
</section>
<div className="footer_foo">
    <p7>©</p7>
    <p8>INGREDIS TRADING COMPANY</p8>
  </div>
        </div>
    );
}

export default Contact_us;
