import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Home from './components/Home';
// import Blogss from './components/Blogss';
import Blog from './components/blog'; // Capitalize 'Blog'
import Enc_prod from './components/Enc_prod';
import Food_ing from './components/Food_ing';
import Feed_ing from './components/Feed_ing';
import ContactUs from './components/about_us';
import Our_tech from './components/our_tech';
import our_products from './components/our_products';
import Contact_us from './components/contact_us';




import './components/Home.css';
import Our_products from './components/our_products';

function App() {
  return (
    <div>

      <Routes>
        <Route index path='/' element={<Home />} /> {/* Home path */}
        <Route path='#' element={<Blog />} /> {/* Blog route */}
        <Route path='/our_tech' element={<Our_tech />} /> {/* our_tech route */}
        <Route path='/Enc_prod' element={<Enc_prod />} /> {/* Enc_prod route */}
        <Route path='/Food_ing' element={<Food_ing />} /> {/* Food_ing route */}
        <Route path='/Feed_ing' element={<Feed_ing />} /> {/* Feed_ing route */}
        <Route path='/ContactUs' element={<ContactUs />} /> {/* Contact Us route */}
        <Route path='/contact_us' element={<Contact_us />} /> {/* Contact Us route */}
        <Route path='/our_products' element={<Our_products />} /> {/* Contact Us route */}
        </Routes>
    </div>
  );
}

export default App;

